// versione 20220823

// Imports
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/media-queries/media-queries'; //mixins media query

// Custom Dev Extreme
$color-accent: #006FDD;
$color-selected-dark: #004891;
$color-light: #BFBFBF;
$color-selected-light: #99CBF4;
$color-text-item-hover: #333333;
$color-hover: #EAF5FF;
$header-background-color: #EFEFEF;
$row-header-height: 34px;
$row-height: 40px;
$border-bottom-color: #CACACA;

.nts-custom-agenda-devextreme {
    .dx-button {
        &.dx-state-hover {
            background-color: $color-hover !important;
        }

        &.dx-state-active {
            background-color: $color-hover !important;
        }
    }

    .dx-calendar-cell {
        &.dx-state-hover {
            background-color: $color-hover !important;
        }

        &.dx-state-active {
            background-color: $color-hover !important;
        }
    }

    .dx-button:not(dx-dashboard-control .dx-button) {
        border: none;
    }

    .dx-button-mode-contained.dx-state-focused:not(dx-dashboard-control .dx-button-mode-contained.dx-state-focused) {
        background-color: $color-hover;
    }

    .dx-button-mode-contained.dx-button-default:not(dx-dashboard-control .dx-button-mode-contained.dx-button-default) {
        background-color: $color-selected-dark;
    }

    .dx-toolbar-text-auto-hide:not(dx-dashboard-control .dx-toolbar-text-auto-hide) {
        .dx-button .dx-button-content {
            padding: 12px;
        }
    }

    // menu tendina
    .dx-menu-base:not(dx-dashboard-control .dx-menu-base) {
        .dx-menu-item .dx-menu-item-content {
            display: flex;
            align-items: center;

            .dx-menu-item-text {
                align-self: center;
                flex-grow: 1;
            }
        }

        .dx-icon {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
        }

        .dx-menu-item-content .dx-menu-item-text {
            padding: 5px;
        }
    }

    .dx-button-text:not(dx-dashboard-control .dx-button-text) {
        flex-grow: 1;
    }

    .dx-button-has-text:not(dx-dashboard-control .dx-button-has-text) {
        //margin-right: 5px;

        .dx-button-content {
            display: flex;
            color: color(accent);
            font-size: 13px !important;
            font-weight: 500;
            align-items: center;
            padding: 8px 8px 8px
        }
    }

    .dx-toolbar:not(dx-dashboard-control .dx-toolbar) {
        .dx-button .dx-icon {
            color: color(accent);
            height: 24px;
            width: 24px;
        }
    }

    // #region campo di ricerca
    //----------------------------------
    // campo di ricerca
    //----------------------------------
    .dx-treelist-search-panel:not(dx-dashboard-control .dx-treelist-search-panel) {
        margin: 0 0 0 0px;

        &.dx-state-hover {
            border-color: $color-selected-dark !important;
        }

        &.dx-state-focused {
            border-color: $color-accent !important;
        }
    }

    .dx-texteditor.dx-editor-outlined:not(dx-dashboard-control .dx-texteditor.dx-editor-outlined) {
        border: 2px solid $color-light;
        border-radius: 8px;
        width: 100%;

        &.dx-state-hover {
            border-color: $color-selected-dark !important;
            background-color: transparent !important;
        }

        &.dx-state-focused {
            border-color: $color-accent !important;
        }
    }

    .dx-widget:not(dx-dashboard-control .dx-widget) {
        font-family: $font-family;

        input,
        textarea {
            font-family: $font-family;
        }
    }


    .dx-texteditor-input:not(dx-dashboard-control .dx-texteditor-input) {
        font-weight: 600;
        color: color(font);
        font-size: 16px !important;
        @include responsive-font-size(regular);
    }

    .dx-treelist-search-panel:not(dx-dashboard-control .dx-treelist-search-panel) {
        margin: 0 0 0 0px;
    }

    .dx-datagrid-search-panel:not(dx-dashboard-control .dx-datagrid-search-panel) {
        margin: 0 0 0 0px;
    }

    //------------------
    // icona delete
    //------------------
    .dx-show-clear-button:not(dx-dashboard-control .dx-show-clear-button) {
        .dx-icon-clear {
            margin-top: -20px;
            font: unset;

            &::before {
                content: " ";
                display: block;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/cancel-circled.svg);
                mask-image: url(/assets/std/svg/core/cancel-circled.svg);
            }
        }
    }

    //------------------
    // icona cerca
    //------------------
    .dx-searchbox:not(dx-dashboard-control .dx-searchbox) {
        .dx-icon-search {
            font: unset;
            margin-top: -20px;

            &::before {
                content: " ";
                display: block;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/search.svg);
                mask-image: url(/assets/std/svg/core/search.svg);
            }
        }
    }

    // testo evidenziato
    .dx-treelist-search-text:not(dx-dashboard-control .dx-treelist-search-text) {
        color: color(white);
        background-color: #47aaffb3;
    }

    //------------------
    // placeholder
    //------------------
    .dx-placeholder:not(dx-dashboard-control .dx-placeholder) {
        font-family: $font-family;
        font-size: 12px !important;
        font-weight: 600;
        color: #898989;
    }

    // #endregion campo di ricerca


    // #region toolbar
    //----------------------------------
    // toolbar
    //----------------------------------
    .dx-toolbar:not(dx-dashboard-control .dx-toolbar) {
        font-family: $font-family;
    }

    // #endregion toolbar


    // #region treelist
    //----------------------------------
    // treelist
    //----------------------------------
    .dx-header-row:not(dx-dashboard-control .dx-header-row) {
        height: $row-header-height;

        .dx-treelist-action {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .dx-treelist:not(dx-dashboard-control .dx-treelist) {
        .dx-column-indicators {
            float: right;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
        }

        .dx-header-row>td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .dx-treelist-headers:not(dx-dashboard-control .dx-treelist-headers) {
        .dx-treelist-table .dx-row>td {
            border-bottom: none;
            height: $row-header-height;
        }
    }

    .dx-header-row:not(dx-dashboard-control .dx-header-row) {
        &>td>.dx-treelist-text-content {
            flex-grow: 1;
            align-self: center;
        }
    }

    .dx-treelist-content:not(dx-dashboard-control .dx-treelist-content) {

        .dx-treelist-table .dx-row>td,
        .dx-treelist-table .dx-row>tr>td {
            vertical-align: middle;
        }
    }

    .dx-treelist-headers:not(dx-dashboard-control .dx-treelist-headers) {
        font-family: $font-family;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        background-color: $header-background-color;
        color: color(font);
    }

    .cell-with-icon-template:not(dx-dashboard-control .cell-with-icon-template) {
        display: flex;
        align-items: end;
        column-gap: 5px;

        svg {
            height: 24px;
            width: 24px;
            color: color(accent);
        }
    }

    .dx-treelist-rowsview:not(dx-dashboard-control .dx-treelist-rowsview) {
        .dx-treelist-empty-space {
            font: unset;

            &::before {
                content: " ";
                visibility: hidden;
            }
        }

        .dx-treelist-expanded {
            font: unset;

            span {
                font: unset;

                &::before {
                    content: " ";
                    display: block;
                    background-size: 18px 18px;
                    height: 18px;
                    width: 18px;
                    margin-left: -8px;
                    margin-top: -10px;
                    background-color: $color-light;
                    -webkit-mask-image: url(/assets/std/svg/core/nav-arrow-down.svg);
                    mask-image: url(/assets/std/svg/core/nav-arrow-down.svg);
                }
            }
        }

        .dx-treelist-collapsed {
            font: unset;

            span {
                font: unset;

                &::before {
                    content: " ";
                    display: block;
                    background-size: 18px 18px;
                    height: 18px;
                    width: 18px;
                    margin-left: -8px;
                    margin-top: -10px;
                    background-color: $color-light;
                    -webkit-mask-image: url(/assets/std/svg/core/nav-arrow-right.svg);
                    mask-image: url(/assets/std/svg/core/nav-arrow-right.svg);
                }
            }
        }

        .dx-selection.dx-row:not(.dx-row-focused):hover>td,
        .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td,
        .dx-selection.dx-row:not(.dx-row-focused)>td,
        .dx-selection.dx-row:not(.dx-row-focused)>tr>td {
            background-color: $color-selected-light;
            color: $color-text-item-hover;

            .dx-treelist-expanded {
                span {
                    &::before {
                        background-color: #47aaffb3;
                    }
                }
            }

            .dx-treelist-collapsed {
                span {
                    &::before {
                        background-color: #47aaffb3;
                    }
                }
            }
        }
    }

    // stile per evidenziare la riga in hover
    tr.dx-row.dx-data-row.dx-column-lines:hover:not(dx-dashboard-control tr.dx-row.dx-data-row.dx-column-lines:hover) {
        background: #2a83ee14;
    }

    .tree-list-container-with-toolbar:not(dx-dashboard-control .tree-list-container-with-toolbar) {
        .dx-treelist-header-panel {
            .dx-toolbar {
                margin: 0;
                //padding-right: 20px;
                background-color: transparent;
            }

            .dx-toolbar-items-container {
                height: 70px;
            }

            .dx-toolbar-before .dx-toolbar-item:last-child {
                padding-right: 10px;
            }

            .dx-selectbox {
                margin: 17px 10px;
            }

            .dx-button {
                margin: 17px 0;
            }
        }

        .informer {
            width: 170px;
            text-align: center;
            color: rgb(0, 0, 0);
        }

        .count {
            padding-top: 15px;
            line-height: 27px;
            margin: 0;
        }
    }

    .grid-container-with-toolbar:not(dx-dashboard-control .grid-container-with-toolbar) {
        .dx-datagrid-header-panel {
            .dx-toolbar {
                margin: 0;
                //padding-right: 20px;
                background-color: transparent;
            }

            .dx-toolbar-items-container {
                height: 70px;
            }

            .dx-toolbar-before .dx-toolbar-item:last-child {
                padding-right: 10px;
            }

            .dx-selectbox {
                margin: 17px 10px;
            }

            .dx-button {
                margin: 17px 0;
            }
        }

        .informer {
            width: 170px;
            text-align: center;
            color: rgb(0, 0, 0);
        }

        .count {
            padding-top: 15px;
            line-height: 27px;
            margin: 0;
        }
    }

    .dx-treelist:not(dx-dashboard-control .dx-treelist) {
        .dx-treelist-content .dx-treelist-table .dx-row .dx-command-edit-with-icons .dx-link {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            float: left;
        }

        .dx-command-drag .dx-treelist-drag-icon {
            vertical-align: unset;
            width: unset;
            height: unset;
            background-position: unset;
            background-size: unset;
            padding: unset;
            font-size: unset;
            text-align: unset;
            line-height: unset;
            justify-content: center;
            font: unset;
        }
    }

    // #endregion treelist

    // #region context menu
    //----------------------------------
    // context menu
    //----------------------------------
    .dx-menu-base:not(dx-dashboard-control .dx-menu-base) {
        font-family: $font-family;
        font-size: 12px;
        font-weight: $font-weight-medium;
    }

    .dx-context-menu:not(dx-dashboard-control .dx-context-menu) {
        .dx-submenu {
            background: color(white);
            border: 0px;
            box-shadow: 1px 2px 10px #0000004d;
            border-radius: 5px;
        }
    }

    .dx-item-content:not(dx-dashboard-control .dx-item-content) {
        .dx-menu-item-content {
            display: flex;
            flex-direction: row;
        }
    }

    .dx-menu-item.dx-state-hover:not(dx-dashboard-control .dx-menu-item.dx-state-hover) {
        color: color(font);
        background-color: $color-selected-light !important;
    }

    .dx-treelist-container.dx-context-menu:not(dx-dashboard-control .dx-treelist-container.dx-context-menu),
    .dx-treelist.dx-context-menu:not(dx-dashboard-control .dx-treelist.dx-context-menu) {
        .dx-menu-items-container {
            .dx-icon-context-menu-sort-asc {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-asc::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/arrow-up.svg);
                mask-image: url(/assets/std/svg/core/arrow-up.svg);
            }

            .dx-icon-context-menu-sort-desc {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-desc::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/arrow-down.svg);
                mask-image: url(/assets/std/svg/core/arrow-down.svg);
            }

            .dx-icon-context-menu-sort-none {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-none::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/cancel.svg);
                mask-image: url(/assets/std/svg/core/cancel.svg);
            }
        }
    }

    // #endregion context menu

    // #region icons
    //----------------------------------
    // icons
    //----------------------------------
    .dx-icon-isblank:not(dx-dashboard-control .dx-icon-isblank) {
        font: unset;

        &::before {
            content: " ";
            display: block;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/circle.svg);
            mask-image: url(/assets/std/svg/core/circle.svg);
        }
    }

    .dx-icon-isnotblank:not(dx-dashboard-control .dx-icon-isnotblank) {
        font: unset;

        &::before {
            content: " ";
            display: block;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/check-circled-outline.svg);
            mask-image: url(/assets/std/svg/core/check-circled-outline.svg);
        }
    }

    .dx-icon-locked:not(dx-dashboard-control .dx-icon-locked) {
        &::before {
            content: " ";
            display: block;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/locked.svg);
            mask-image: url(/assets/std/svg/core/locked.svg);
        }
    }

    .dx-sort-down:not(dx-dashboard-control .dx-sort-down) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(font);
            -webkit-mask-image: url(/assets/std/svg/core/arrow-down.svg);
            mask-image: url(/assets/std/svg/core/arrow-down.svg);
        }
    }

    .dx-sort-up:not(dx-dashboard-control .dx-sort-up) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(font);
            -webkit-mask-image: url(/assets/std/svg/core/arrow-up.svg);
            mask-image: url(/assets/std/svg/core/arrow-up.svg);
        }
    }

    .dx-treelist-drag-icon:not(dx-dashboard-control .dx-treelist-drag-icon) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
            mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
        }
    }

    .dx-icon-edit:not(dx-dashboard-control .dx-icon-edit) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/edit-pencil.svg);
            mask-image: url(/assets/std/core/edit-pencil.svg);
        }
    }

    .dx-icon-showpanel:not(dx-dashboard-control .dx-icon-showpanel) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/move-ruler.svg);
            mask-image: url(/assets/std/svg/core/move-ruler.svg);
        }
    }

    .dx-icon-columnfield:not(dx-dashboard-control .dx-icon-columnfield) {
        font: unset !important;
        display: flex !important;
        align-items: center;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/view-columns-2.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/view-columns-2.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-smalliconslayout:not(dx-dashboard-control .dx-icon-smalliconslayout) {
        font: unset !important;
        display: flex !important;
        align-items: center;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/view-columns-3.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/view-columns-3.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-revert:not(dx-dashboard-control .dx-icon-revert) {
        font: unset !important;
        display: flex !important;
        align-items: center;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/undo.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/undo.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-save:not(dx-dashboard-control .dx-icon-save) {
        font: unset !important;
        display: flex !important;
        align-items: center;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/check.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/check.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-warning:not(dx-dashboard-control .dx-icon-warning) {
        font: unset !important;
        display: flex !important;
        float: left;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/warning.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/warning.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-add:not(dx-dashboard-control .dx-icon-add) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/plus.svg);
            mask-image: url(/assets/std/svg/core/plus.svg);
        }
    }


    .dx-icon-arrowleft:not(dx-dashboard-control .dx-icon-arrowleft) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/arrow-back.svg);
            mask-image: url(/assets/std/core/arrow-back.svg);
        }
    }

    .dx-icon-arrowright:not(dx-dashboard-control .dx-icon-arrowright) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/arrow-forward.svg);
            mask-image: url(/assets/std/core/arrow-forward.svg);
        }
    }

    .dx-icon-link:not(dx-dashboard-control .dx-icon-link) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/link.svg);
            mask-image: url(/assets/std/svg/core/link.svg);
        }
    }

    .dx-icon-range:not(dx-dashboard-control .dx-icon-range) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/ruler.svg);
            mask-image: url(/assets/std/svg/core/ruler.svg);
        }
    }

    .dx-icon-remove:not(dx-dashboard-control .dx-icon-remove) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/cancel.svg);
            mask-image: url(/assets/std/core/cancel.svg);
        }
    }

    .dx-icon-trash:not(dx-dashboard-control .dx-icon-trash) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/trash.svg);
            mask-image: url(/assets/std/core/trash.svg);
        }
    }

    .dx-icon-edit-button-addrow:not(dx-dashboard-control .dx-icon-edit-button-addrow) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/plus.svg);
            mask-image: url(/assets/std/core/plus.svg);
        }
    }

    .dx-icon-repeat:not(dx-dashboard-control .dx-icon-repeat) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/repeat.svg);
            mask-image: url(/assets/std/svg/core/repeat.svg);
        }
    }

    .dx-datagrid-drag-icon:not(dx-dashboard-control .dx-datagrid-drag-icon) {
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
            mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
        }
    }

    .dx-icon-close:not(dx-dashboard-control .dx-icon-close) {
        font: unset;
        line-height: 24px;

        &::before {
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/cancel.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/cancel.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    .dx-icon-playlist-add:not(dx-dashboard-control .dx-icon-playlist-add) {
        font: unset;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/playlist-add.svg);
            -webkit-mask-repeat: repeat-x;
            -webkit-mask-position: center;
            -webkit-mask-size: 24px 24px;
            mask-image: url(/assets/std/svg/core/playlist-add.svg);
            mask-repeat: repeat-x;
            mask-position: center;
            mask-size: 24px 24px;
        }
    }

    // #endregion icons



    // #region grid
    //----------------------------------
    // grid
    //----------------------------------
    .dx-datagrid:not(dx-dashboard-control .dx-datagrid) {

        .dx-column-indicators {
            float: right;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
        }

        .dx-checkbox {
            &.dx-state-focused {
                background-color: unset;
                outline: unset;
                outline-color: unset;
                outline-width: unset;
                outline-offset: unset;
                border-radius: unset;

                .dx-checkbox-container {
                    border-color: $color-accent !important;

                    .dx-checkbox-icon::before {
                        border-color: $color-accent !important;
                    }
                }
            }
        }

        .dx-column-indicators {
            float: unset;
            align-self: center;
            display: unset;
            vertical-align: unset;
            white-space: unset;
        }
    }

    .grid-button:not(dx-dashboard-control .grid-button) {
        .dx-button-content {
            display: inline-flex;
            padding: 0px;
        }
    }

    .dx-datagrid-content:not(dx-dashboard-control .dx-datagrid-content) {
        .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons .dx-link {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            float: left;
            margin: 0px 2px;
        }

        .dx-datagrid-table .dx-row>td,
        .dx-datagrid-table .dx-row>tr>td {
            vertical-align: middle;
        }

        .dx-datagrid-table .dx-row>td,
        .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
            vertical-align: middle;
        }
    }

    .dx-header-row:not(dx-dashboard-control .dx-header-row) {
        height: $row-header-height;

        .dx-datagrid-action {
            /*display: flex;
        flex-direction: row-reverse;*/
            position: relative;
        }
    }

    .dx-data-row:not(dx-dashboard-control .dx-data-row) {
        height: $row-height;
    }

    .dx-row:not(dx-dashboard-control .dx-row) {
        .dx-data-row {
            .dx-header-row {
                height: $row-header-height;

                .dx-datagrid-action {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }

    .dx-header-row:not(dx-dashboard-control .dx-header-row) {
        &>td>.dx-datagrid-text-content {
            flex-grow: 1;
            align-self: center;
        }
    }

    .dx-datagrid:not(dx-dashboard-control .dx-datagrid) {
        .dx-header-row>td {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .dx-command-drag .dx-datagrid-drag-icon {
            vertical-align: unset;
            width: unset;
            height: unset;
            background-position: unset;
            background-size: unset;
            padding: unset;
            font-size: unset;
            text-align: unset;
            line-height: unset;
            font: unset;
            justify-content: center;

            &::before {
                content: " " !important;
                font-family: unset !important;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
                mask-image: url(/assets/std/svg/core/one-finger-select-hand-gesture.svg);
            }
        }
    }

    .dx-datagrid-headers:not(dx-dashboard-control .dx-datagrid-headers) {
        font-family: $font-family;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        background-color: $header-background-color;
        color: color(font);

        .dx-datagrid-table {
            .dx-row>td {
                border-bottom: none;
                height: $row-header-height;
            }

            .dx-row>td {
                border-bottom: 0;
                padding: 0px 8px 0px 8px;
            }
        }
    }


    .dx-datagrid-container.dx-context-menu:not(dx-dashboard-control .dx-datagrid-container.dx-context-menu),
    .dx-datagrid.dx-context-menu:not(dx-dashboard-control .dx-datagrid.dx-context-menu) {
        .dx-menu-items-container {
            .dx-icon-context-menu-sort-asc {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-asc::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/arrow-up.svg);
                mask-image: url(/assets/std/svg/core/arrow-up.svg);
            }

            .dx-icon-context-menu-sort-desc {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-desc::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/arrow-down.svg);
                mask-image: url(/assets/std/svg/core/arrow-down.svg);
            }

            .dx-icon-context-menu-sort-none {
                font: unset;
                width: unset;
                height: unset;
                background-position: unset;
                background-size: unset;
                padding: unset;
                text-align: unset;
            }

            .dx-icon-context-menu-sort-none::before {
                content: " ";
                display: flex;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                background-color: color(accent);
                -webkit-mask-image: url(/assets/std/svg/core/cancel.svg);
                mask-image: url(/assets/std/svg/core/cancel.svg);
            }
        }
    }

    // #endregion grid




    // #region form
    //----------------------------------
    // form
    //----------------------------------
    .dx-fieldset:not(dx-dashboard-control .dx-fieldset) {
        margin: 10px 0px;

        .dx-field {
            display: flex;

            .dx-field-label {
                align-self: center;
                overflow: hidden;
                font-family: $font-family;
                font-size: 12px !important;
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-grow: 1;
            }
        }
    }

    .dx-form-group:not(dx-dashboard-control .dx-form-group) {
        padding: 5px 15px;
        border-bottom-color: $border-bottom-color;
        border-bottom-width: 1.5px;
        border-bottom-style: solid;
    }

    .dx-box-item-content:not(dx-dashboard-control .dx-box-item-content) {
        padding: 7px 0px !important;
    }

    .dx-form-group-caption:not(dx-dashboard-control .dx-form-group-caption) {
        font-size: 20px;
        margin-right: 17px;
        font-family: $font-family;
        font-size: 18px;
        font-weight: $font-weight-semibold;
        color: color(font);
    }

    /*.dx-popup-content:not(dx-dashboard-control .dx-popup-content) {
    padding: 0px;
    }*/

    .dx-form-group-with-caption:not(dx-dashboard-control .dx-form-group-with-caption) {
        &>.dx-form-group-content {
            padding-top: unset;
            margin-top: unset;
            border-top: unset;
            padding-bottom: unset;
        }
    }

    .dx-layout-manager:not(dx-dashboard-control .dx-layout-manager) {
        .dx-field-item:not(.dx-first-row) {
            padding-top: unset;
        }
    }

    .dx-box-item-content:not(dx-dashboard-control .dx-box-item-content) {
        padding: 5px 15px;
    }

    .dx-field-item-help-text:not(dx-dashboard-control .dx-field-item-help-text),
    .dx-field-item-label-text:not(dx-dashboard-control .dx-field-item-label-text) {
        font-family: $font-family;
        font-weight: 500;
        font-size: 16px !important;
        @include responsive-font-size(regular);
    }

    // #endregion form

    // #region combo
    //----------------------------------
    // dropdown combo
    //----------------------------------
    .dx-dropdownlist-popup-wrapper.dx-popup-wrapper:not(dx-dashboard-control .dx-dropdownlist-popup-wrapper.dx-popup-wrapper) {
        .dx-overlay-content {
            border-top-width: 0;
            border-bottom-width: 1px;
            box-shadow: unset;
            border: 2px solid $color-light;
            border-radius: 8px;
            z-index: 1500 !important;
            margin-top: 4px;
        }
    }

    .dx-list:not(dx-dashboard-control .dx-list) {
        .dx-empty-message {
            font-size: 12px !important;
            font-weight: $font-weight-semibold;
        }
    }

    .dx-list-item-content:not(dx-dashboard-control .dx-list-item-content) {
        font-size: 12px !important;
        font-weight: $font-weight-semibold;
    }

    /*.dx-list-item.dx-state-hover:not(dx-dashboard-control .dx-list-item.dx-state-hover) {
        color: color(font);

        background-color: $color-hover  !important;
    }*/

    .dx-list-item.dx-list-item-selected:not(dx-dashboard-control .dx-list-item.dx-list-item-selected) {
        background-color: $color-selected-light !important;
    }

    .dx-list-item:not(dx-dashboard-control .dx-list-item) {
        line-height: 25px;

        &:first-of-type {
            border-radius: 5px 5px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 5px 5px;
        }

        &.dx-state-hover {
            background-color: $color-hover !important;
        }
    }

    .dx-dropdowneditor-icon:not(dx-dashboard-control .dx-dropdowneditor-icon) {
        font: unset;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(font);
            -webkit-mask-image: url(/assets/std/svg/core/arrow-down.svg);
            mask-image: url(/assets/std/svg/core/arrow-down.svg);
        }
    }

    .dx-dropdowneditor-button.dx-state-active:not(dx-dashboard-control .dx-dropdowneditor-button.dx-state-active),
    .dx-dropdowneditor.dx-dropdowneditor-active:not(dx-dashboard-control .dx-dropdowneditor.dx-dropdowneditor-active) {
        .dx-dropdowneditor-icon {
            background-color: transparent;
        }
    }

    .dx-dropdowneditor.dx-state-active:not(dx-dashboard-control .dx-dropdowneditor.dx-state-active),
    .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered):not(dx-dashboard-control .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered)) {
        .dx-dropdowneditor-icon {
            background-color: transparent;
        }
    }

    .dx-dropdowneditor-icon:not(dx-dashboard-control .dx-dropdowneditor-icon) {
        &::before {
            margin-top: -10px;
            margin-left: -10px;
        }
    }

    // #endregion dropdown combo

    // #region checkbox
    //----------------------------------
    // checkbox
    //----------------------------------
    .dx-checkbox:not(dx-dashboard-control .dx-checkbox) {

        .dx-checkbox-container {
            background-color: #fff;
            /* position: relative; */
            /* display: inline-block; */
            width: 22px;
            height: 22px;
            cursor: inherit;
            outline: none;
            border: 2px solid #bfbfbf;
            border-radius: 3px;
            /* vertical-align: middle; */

            .dx-checkbox-icon {
                /*width: unset;
            height: unset;
            border-radius: unset;*/
                border: unset;
                /*background-color: unset;
            flex-shrink: unset;
            position: relative;
            background-position: unset;
            background-size: unset;
            background-repeat: unset;*/
            }
        }

        &.dx-state-hover {
            .dx-checkbox-container {
                border-color: $color-selected-dark !important;

                .dx-checkbox-icon::before {
                    border-color: $color-selected-dark !important;
                }
            }
        }

        &.dx-state-focused {
            background-color: $color-hover;
            outline: solid;
            outline-color: $color-hover;
            outline-width: 10px;
            outline-offset: 3px;
            border-radius: 20px;

            .dx-checkbox-container {
                border-color: $color-accent !important;

                .dx-checkbox-icon::before {
                    border-color: $color-accent !important;
                }
            }
        }
    }

    .dx-state-disabled:not(dx-dashboard-control .dx-state-disabled) {
        .dx-checkbox-container {
            opacity: .4;
        }

    }

    .dx-checkbox.dx-checkbox-checked:not(dx-dashboard-control .dx-checkbox.dx-checkbox-checked) {
        .dx-checkbox-container {
            border-color: $color-accent;
        }
    }

    .dx-checkbox.dx-state-focused:not(dx-dashboard-control .dx-checkbox.dx-state-focused) {
        .dx-checkbox-icon {
            border-color: transparent;
        }
    }

    .dx-checkbox.dx-state-hover:not(dx-dashboard-control .dx-checkbox.dx-state-hover) {
        .dx-checkbox-icon {
            border-color: transparent;
        }
    }

    .dx-checkbox-checked:not(dx-dashboard-control .dx-checkbox-checked) {
        .dx-checkbox-icon {
            font: unset;

            &::before {
                position: relative;
                left: 13px;
                top: 9px;
                width: 5px;
                height: 14px;
                border: 4px solid;
                border: 4px solid;
                border-width: 0px 2px 2px 0;
                transform: rotate(45deg);
                display: block;
                border-color: $color-accent;
                content: "";
            }
        }
    }

    .dx-datagrid-checkbox-size:not(dx-dashboard-control .dx-datagrid-checkbox-size) {
        .dx-checkbox-icon {
            height: 22px;
            width: 22px;
        }
    }

    .dx-datagrid-checkbox-size.dx-checkbox-indeterminate:not(dx-dashboard-control .dx-datagrid-checkbox-size.dx-checkbox-indeterminate) {

        .dx-checkbox-icon::before {
            height: 8px;
            width: 8px;
            margin-top: -4px;
            margin-left: -6px;
            border-radius: 0;
        }
    }

    // #endregion checkbox


    // #region modale
    //----------------------------------
    // modale
    //----------------------------------
    .dx-popup-wrapper:not(dx-dashboard-control .dx-popup-wrapper) {
        &>.dx-overlay-content {
            //position: relative;
            background-color: #fff;
            background-clip: padding-box;
            border: none;
            box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
            outline: 0;
            border-radius: 25px;
            font-family: "Poppins";
            font-size: 13px;
            font-weight: 600;
        }
    }

    .dx-popup-title:not(dx-dashboard-control .dx-popup-title) {
        margin: 0;
        line-height: 1.428571429;
        flex: 1;
        color: #212121;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: unset;

        .dx-closebutton {
            border-radius: 8px;
            border: unset !important;
        }
    }

    .dx-closebutton:not(dx-dashboard-control .dx-closebutton) {
        &:not(.dx-datagrid-action) {
            display: flex;
            border: none;
            height: 100%;
            cursor: pointer;
            background-color: #fff;
            outline: none;
            padding: 0;
            margin: 0;
            align-self: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            min-width: 35px;
            min-height: 35px;
        }

        &.dx-state-hover {
            background-color: $color-hover !important;
        }
    }

    // #endregion modale



    .nts-text-button:not(dx-dashboard-control .nts-text-button) {
        display: flex;
        border: none;
        height: 100%;
        cursor: pointer;
        background-color: color(white);
        outline: none;
        border-radius: 8px;
        padding: 0;
        margin: 0;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        min-width: 35px;
        min-height: 35px;

        .dx-button-content {
            align-self: center;

            .dx-button-text {
                display: flex;
                align-self: center;
                font-family: $font-family;
                color: $color-accent;
                line-height: 1;
                -webkit-user-select: none;
                user-select: none;
                font-weight: $font-weight-semibold;
                font-size: 17px !important;
                @include responsive-font-size(regular);
            }
        }

        &.dx-state-focused {
            outline: none;
            background-color: $color-hover;
        }

        &.dx-state-hover {
            outline: none;
            background-color: $color-hover;
        }
    }

    .nts-filled-button:not(dx-dashboard-control .nts-filled-button) {
        height: 36px;
        display: flex;
        align-self: center;
        cursor: pointer;
        border-radius: 18px;
        background-color: $color-accent;
        justify-content: center;

        .dx-button-content {
            display: flex;
            border: none;
            padding: 8px 15px;
            justify-content: center;
            align-self: center;

            .dx-button-text {
                display: flex;
                align-self: center;
                font-family: $font-family;
                color: color(white);
                line-height: 1;
                -webkit-user-select: none;
                user-select: none;
                font-weight: $font-weight-semibold;
                font-size: 17px !important;
                @include responsive-font-size(regular);
            }
        }

        &.dx-state-focused {
            outline: solid;
            outline-color: $color-hover;
            outline-width: 5px;
            background-color: $color-accent;
        }

        &.dx-state-hover {
            outline: solid;
            outline-color: $color-hover;
            outline-width: 5px;
            background-color: $color-accent;
        }
    }

    .dx-button-has-icon:not(dx-dashboard-control .dx-button-has-icon) {
        border: 0px;

        .dx-icon {
            width: 20px;
        }

        &.dx-state-focus {
            background-color: $color-hover;
        }

        &.dx-state-hover {
            background-color: $color-hover
        }
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal) {
        background-color: #fff;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-state-hover:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-state-hover) {
        background-color: $color-hover;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-state-focused:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-state-focused) {
        background-color: $color-hover;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected) {
        background-color: $color-hover;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected.dx-state-hover:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected.dx-state-hover) {
        background-color: $color-hover;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected.dx-state-focused:not(dx-dashboard-control .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected.dx-state-focused) {
        background-color: $color-hover;
    }

    // #region diagram
    //----------------------------------
    // diagram
    //----------------------------------
    .dx-diagram-i-button-lock:not(dx-dashboard-control .dx-diagram-i-button-lock),
    .dx-diagram-i-menu-lock:not(dx-dashboard-control .dx-diagram-i-menu-lock) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/locked.svg);
            mask-image: url(/assets/std/core/locked.svg);
        }
    }

    .dx-state-selected:not(dx-dashboard-control .dx-state-selected) {
        background-color: $color-selected-light !important;
    }

    .dx-diagram-i-button-select-all:not(dx-dashboard-control .dx-diagram-i-button-select-all),
    .dx-diagram-i-menu-select-all:not(dx-dashboard-control .dx-diagram-i-menu-select-all) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/cursor-pointer.svg);
            mask-image: url(/assets/std/svg/core/cursor-pointer.svg);
        }
    }

    .dx-diagram-i-button-fullscreen:not(dx-dashboard-control .dx-diagram-i-button-fullscreen),
    .dx-diagram-i-menu-fullscreen:not(dx-dashboard-control .dx-diagram-i-menu-fullscreen) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/expand.svg);
            mask-image: url(/assets/std/svg/core/expand.svg);
        }
    }

    .dx-diagram-i-button-toolbox-close:not(dx-dashboard-control .dx-diagram-i-button-toolbox-close) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/eye-empty.svg);
            mask-image: url(/assets/std/svg/core/eye-empty.svg);
        }
    }

    .dx-diagram-i-button-toolbox-open:not(dx-dashboard-control .dx-diagram-i-button-toolbox-open) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/eye-close.svg);
            mask-image: url(/assets/std/svg/core/eye-close.svg);
        }
    }

    .dx-toolbar-text-auto-hide:not(dx-dashboard-control .dx-toolbar-text-auto-hide) {
        .dx-button .dx-icon {
            width: 24px;
            height: 24px;
            line-height: 24px;
        }
    }

    .dx-diagram-i-button-properties-panel-open:not(dx-dashboard-control .dx-diagram-i-button-properties-panel-open) {
        background-image: unset;
        font: unset !important;
        display: flex !important;

        &::before {
            content: " " !important;
            font-family: unset !important;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            background-color: color(accent);
            -webkit-mask-image: url(/assets/std/svg/core/more-vert.svg);
            mask-image: url(/assets/std/svg/core/more-vert.svg);
        }
    }

    .dx-diagram-floating-toolbar-container:not(dx-dashboard-control .dx-diagram-floating-toolbar-container) {
        .dx-icon-close {
            &::before {
                background-color: color(white);
            }
        }

        .dx-diagram-i-button-properties-panel-open {
            &::before {
                background-color: color(white);
            }
        }
    }

    .dx-diagram-toolbox-popup.dx-popup-wrapper:not(dx-dashboard-control .dx-diagram-toolbox-popup.dx-popup-wrapper) {
        .dx-overlay-content {
            border-radius: 0px;
        }
    }

    .dx-diagram-toolbox-popup:not(dx-dashboard-control .dx-diagram-toolbox-popup) {
        .dx-button-has-icon .dx-icon.dx-icon-diagram-toolbox-drag {
            width: 12px;
            height: 12px;
        }
    }

    // #endregion diagram

    // filtri
    .dx-filterbuilder:not(dx-dashboard-control .dx-filterbuilder) {

        .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text .dx-filterbuilder-item-value-text,
        .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-group-operation,
        .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-item-field,
        .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-item-operation {
            min-width: unset;
        }
    }

    .dx-filterbuilder-overlay:not(dx-dashboard-control .dx-filterbuilder-overlay) {
        .dx-overlay-content.dx-popup-normal {
            box-shadow: unset;
            border: 2px solid #BFBFBF;
            border-radius: 8px;
            z-index: 1500 !important;
            margin-top: 4px;
        }
    }

    .dx-filterbuilder:not(dx-dashboard-control .dx-filterbuilder) {
        .dx-filterbuilder-group .dx-filterbuilder-group-item {
            display: flex;
            white-space: nowrap;
            height: 30px;
            margin: 5px;
            align-items: center;

            .dx-filterbuilder-action-icon {
                margin-bottom: 0px;
            }
        }
    }

    .dx-filterbuilder-overlay.dx-filterbuilder-operations:not(dx-dashboard-control .dx-filterbuilder-overlay.dx-filterbuilder-operations) {
        .dx-treeview-item .dx-icon {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            font-size: 24px;
            line-height: 24px;
        }
    }


    // #startregion agenda

    // Lista calendari
    .calendar-list-calendar {
        &:hover {
            dx-drop-down-button {
                visibility: visible;
            }
        }

        .dx-dropdownbutton-action.dx-buttongroup-last-item.dx-button .dx-button-content {
            padding-right: 10px;
        }

        .nts-custom-agenda-devextreme .calendar-list-calendar .dx-dropdownbutton-action.dx-button .dx-button-content {
            padding-left: 10px;
            padding-right: 10px;
        }

        dx-drop-down-button {
            .dx-buttongroup-item {
                border: 0px;
            }

            .dx-state-hover {
                background-color: $color-hover;
            }

            visibility: hidden;

            @include respond-below(md) {
                visibility: visible;
            }

            .dx-icon-spindown {
                display: none;
            }
        }
    }

    .calendar-list-content {
        p-tree {
            .p-tree {
                border: 0px;
                padding: 0px;
                height: calc(100vh - 364px);
                overflow-y: auto;
            }

            .p-treenode-label {
                width: 100%;
            }

            .p-tree .p-treenode-children {
                padding: 0 0 0 5px;
            }

            .p-treenode-leaf>.p-treenode-content .p-tree-toggler {
                visibility: hidden;
                display: none;
            }

            p-treenode {
                .p-checkbox .p-checkbox-box {
                    border-radius: 10px;
                }

                .p-checkbox-disabled {
                    display: none;
                }
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
                background: transparent;
                color: #333333;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
                box-shadow: unset;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
                box-shadow: unset;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
                color: #a6a6a6;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
                color: #007ad9;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
                background: $color-hover;
                color: #333333;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
                margin-right: 0.1rem;
            }
        }

        .dx-item.dx-list-item.dx-state-hover {
            background: $color-hover !important;
        }
    }

    .forbidden {
        display: none;
    }

    .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content,
    .dx-scheduler-header .dx-toolbar .dx-toolbar-menu-container {
        margin-left: 65px;
    }

    .dx-scheduler-header {
        background-color: #FFFFFF;

        .dx-toolbar {
            .dx-scheduler-navigator {
                .dx-button-text {
                    text-transform: uppercase;
                }
            }

        }
    }

    .dx-scheduler-container {
        .is-not-working-time {
            background: #e3e3e3;
            height: 100%;
            width: 100%;
        }
    }




    dx-scheduler {
        width: auto;

        @include respond-above(md) {

            width: calc(100vw - 350px);
        }
    }

    .dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
        min-width: 160px;
    }

    .dx-scheduler-header-panel-empty-cell {
        width: 100px !important;

        @include respond-above(xs) {

            width: inherit;
        }
    }

    .dx-scheduler-all-day-title {
        font-size: 12px;
        width: 100px;

        @include respond-above(xs) {

            width: inherit;
        }
    }

    .dx-scheduler-appointment-tooltip-wrapper {
        .dx-popup-content {
            border-radius: 25px 25px 25px 25px;
        }

        .dx-list-item:not(dx-dashboard-control .dx-list-item) {
            line-height: 25px;

            &:first-of-type {
                border-radius: 25px 25px 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 25px 25px;
            }

            &:only-of-type {
                border-radius: 25px 25px 25px 25px;
            }



            &.dx-state-active {
                background-color: $color-hover !important;
                color: $color-text-item-hover;

                nts-text-button {
                    button {
                        background-color: $color-hover;

                        &:hover {
                            background-color: $color-selected-light;
                        }
                    }
                }
            }

            &.dx-state-hover {
                background-color: $color-hover !important;

                nts-text-button {
                    button {
                        background-color: $color-hover;

                        &:hover {
                            background-color: $color-selected-light;
                        }
                    }
                }
            }

            &.dx-state-focused {
                background-color: $color-hover !important;
                color: $color-text-item-hover;

                nts-text-button {
                    button {
                        background-color: $color-hover;

                        &:hover {
                            background-color: $color-selected-light;
                        }
                    }
                }
            }
        }
    }

    .dx-scheduler-date-table-cell {
        &.dx-state-hover {
            background-color: $color-hover !important;
        }

        &.dx-state-active {
            background-color: $color-hover !important;
        }
    }

    // #endregion agenda


}
