@use "node_modules/ag-grid-community/styles" as ag;

$color-selected-light: #99CBF4;

@include ag.grid-styles((
    theme: nts,

    extend-theme: alpine,

    // use theme parameters where possible
    alpine-active-color: $color-selected-light,

    // Height of grid rows
    row-height: 34px,

    // Height of header rows
    header-height: var(--ag-row-height),

    // Fonts
    font-family: ($font-family),

    // Color to draw around selected cell ranges
    range-selection-border-color: color(accent),

    //
    // BORDERS
    //

    // Draw borders around most UI elements
    borders: true,

    // Draw the few borders that are critical to UX, e.g. between headers and rows.
    borders-critical: var(--ag-borders),

    // Draw decorative borders separating UI elements within components
    borders-secondary: var(--ag-borders),

    // Draw borders around sidebar tabs so that the active tab appears connected to the current tool panel
    borders-side-button: var(--ag-borders),

    // Colour for border around major UI components like the grid itself, headers, footers and tool panels
    border-color: #E2E2E2,

    // Colour for borders used to separate elements within a major UI component
    secondary-border-color: var(--ag-border-color),

    // Colour of the border between grid rows, or null to display no border
    row-border-color: var(--ag-secondary-border-color),

    // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
    cell-horizontal-border: solid #E2E2E2,

    selected-row-background-color: $color-selected-light,

    // Separator between columns in the header. Displays between all header cells For best UX, use either this or header-column-resize-handle but not both
    header-column-separator-display: block,
    header-column-separator-height: 100%,
    header-column-separator-width: 1px,
    header-column-separator-color: rgba(var(--border-color), .5),

    // Background colour for all headers, including the grid header, panels etc
    header-background-color: #EFEFEF,

    // Background colour applied to every other row or null to use background-color for all rows
    odd-row-background-color: #F9F9F9,

    // Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the rollover)
    row-hover-color: color(hover),

    // Horizontal padding for grid and header cells (vertical padding is not set explicitly, but inferred from row-height / header-height
    cell-horizontal-padding: 11px,
));

.ag-theme-nts {
    
    
    @include responsive-font-size(regular);

    .ag-root-wrapper-body {
        min-height: 125px;
    }

    .ag-header-row {
        font-weight: $font-weight-semibold;
    }

    .ag-row {
        font-weight: $font-weight-medium;
        @include responsive-font-size(regular);
        // @include respond-below(sm) {
        //     height: 40px !important;
        // }
    }

    .ag-ltr, .ag-rtl {
        .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            // outline-color: color(accent);
            // outline-style: solid;
            // outline-width: 1px;
            // outline-offset: 0;
            border-color: color(accent)
        }
    }

    .ag-cell{
        @include respond-below(sm) {
            line-height: 38px;
        }
    }

    .ag-cell-value{
        flex-grow: 1;
    }
    
    .ag-cell-inline-editing {
        border-color: color(accent) !important;
        @include respond-below(sm) {
            height: 40px !important;
        }
    }

    .ag-popup-editor {
        border-color: color(accent) !important;
    }
    
    // BEGIN GESTIONE SCROLL ORIZZONTALE GRIGLIE SUI DISPOSITIVI
    /* smartphones, touchscreens */
    @media (hover: none) and (pointer: coarse) {
        /* ... */
    }
    /* stylus-based screens */
    @media (hover: none) and (pointer: fine) {
        /* ... */
    }
    /* Nintendo Wii controller, Microsoft Kinect */
    @media (hover: hover) and (pointer: coarse) {
        /* ... */
    }
    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        .ag-center-cols-viewport {
            overflow-x: hidden;
        }
    }
    // END GESTIONE SCROLL ORIZZONTALE GRIGLIE SUI DISPOSITIVI
}
